import request from "./http";

export const getDistributorTotal = async (params) => await request({
    method: 'get',
    url: `/distributor/total`,
    params: params
})
export const getDistributorDetails = async (params) => await request({
    method: 'get',
    url: `/distributor/details`,
    params: params
})